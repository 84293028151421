import React, {useState, useRef, useLayoutEffect} from "react"
import styled from "styled-components"

export const AccordianPanel = styled.div`
    display: flex;
    flex-direction: column;
    ${({verticalAlign}) => verticalAlign? `align-items: ${verticalAlign};` : ""}
    ${({horizontalAlign}) => horizontalAlign? `justify-content: ${horizontalAlign};` : ""}
    ${({textAlign}) => textAlign ? `text-align: ${textAlign};` : ""}
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {${responsiveStyle}}` : ""}
`

export const AccordianDivider = styled.div`
    height: 2px;
    width: 50px;
    background-color: ${({theme}) => theme.colors.divider};
    margin-top: 10px;
    margin-bottom: 10px;
`

const StyledButton = styled.button`
    background-color: transparent;
    border-style: none;
    padding: 0;
    outline: none;
    cursor: pointer;
    color: ${({theme}) => theme.colors.text};

    &:hover {
        color: ${({theme}) => theme.colors.primary};
    }
`

const StyledTitle = styled.h4`
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
`

const StyledContent = styled.div`
    opacity: ${({show}) => show ? 1 : 0};
    visibility: ${({show}) => show ? "visible" : "hidden"};
    ${({show}) => show ? "" : `overflow: hidden;`}
    ${({maxHeight}) => maxHeight ? `max-height: ${maxHeight}px;` : ""}
    transition: visibility 200ms cubic-bezier(.455, .03, .515, .955), 
        opacity 200ms cubic-bezier(.455, .03, .515, .955), 
        max-height 400ms cubic-bezier(.455, .03, .515, .955);
`
const StyledWrapper = styled.div`
`

export default ({title, children, expand, ...rest}) => {
    const [open, setOpen] = useState(true)
    const [contentHeight, setContentHeight] = useState()
    const content = useRef()

    useLayoutEffect(() => {
        setOpen(expand || false)
    }, [expand])

    useLayoutEffect(() => {
        setContentHeight(content.current.clientHeight*3)
    },[])

    return (
        <StyledWrapper {...rest}>
            <StyledButton onClick={() => setOpen(!open)}> 
                <StyledTitle>{title}</StyledTitle>
            </StyledButton>
            <StyledContent ref={content} show={open} maxHeight={open ? contentHeight : "0"}>{children}</StyledContent>
        </StyledWrapper>
    )
}