import React, { useState } from "react"
import {Link} from "gatsby"

import SiteLayout from "../../components/sitelayout"
import Header from "../../components/header"
import SEO from "../../components/seo"
import Container from "../../components/container"
import GoogleButton from "../../components/googlebutton"
import TextSpan from "../../components/textspan"
import Button, { ButtonWrapper } from "../../components/button"
import HeroWrapper from "../../components/herowrapper"
import Wrapper from "../../components/wrapper"
import FlexRowWrapper from "../../components/flexrowwrapper"
import FlexColumnWrapper from "../../components/flexcolumnwrapper"
import Vector from "../../components/vector"
import { SectionHeader } from "../../components/section"
import Step from "../../components/step"
import Accordian, { AccordianPanel, AccordianDivider } from "../../components/accordian"
import ExternalLink from "../../components/externallink"

import { useAuth } from "../../services/auth"
import { useTheme } from "../../services/theme"

import hubspotWebflow from "../../images/hubspotwebflow.svg"
import heroBlob from "../../images/lightblueblob.svg"
import solutionBlob from "../../images/darkblueblob.svg"
import sittingDoodle from "../../images/sittingdoodle.svg"
import zenDoodle from "../../images/zendoodle.svg"
import connectIcon from "../../images/connecticon.svg"
import addIcon from "../../images/addicon.svg"
import profileIcon from "../../images/profileicon.svg"
import faqWave from "../../images/grayfade.svg"
import faqDoodle from "../../images/questiondoodle.svg"

const HubspotWebflowFormTool = () => {
  const auth = useAuth()
  const user = auth.getUser()
  const theme = useTheme()
  const [waiting, setWaiting] = useState(false)

  return (
    <SiteLayout
      signupWaiting={waiting}
      setSignupWaiting={setWaiting}
    >
      <SEO title="Hubspot Webflow Form Tool"/>
      <Wrapper 
        backgroundImage={`url(${heroBlob})`}
        backgroundSize={`auto 100%`}
        backgroundPosition={`top left`}
        backgroundRepeat={`no-repeat`}
        minHeight="93vh"
      >
        <Container>
          <FlexRowWrapper horizontalAlign="space-between" breakpoint={theme.breakpoints.mobile} responsiveStyle="justify-content: center;">
            <HeroWrapper paddingLeft="5%" breakpoint={theme.breakpoints.mobile} responsiveStyle="padding-left: 0px;">
              <Header
                paddingTop="20vh"
                title="Use Webflow forms with Hubspot."
                subTitle="The beauty of Webflow’s designer meets the power of Hubspot’s marketing automation. No coding needed."
                align="left"
                maxWidth="80%"
              />
              <ButtonWrapper align="left" breakpoint={theme.breakpoints.mobile} responsiveAlign="center">
                {
                  (auth.isLoggedIn() && !waiting) && 
                    <Link to="/app/websites">
                      <Button>View my websites</Button>
                    </Link>
                  
                }
                <GoogleButton 
                  minWidth="277px" 
                  waiting={waiting}
                  setWaiting={setWaiting}
                  show={!auth.isLoggedIn() || waiting}
                >
                  Sign up with Google <TextSpan fontStyle="italic" textTransform="none">- It's free</TextSpan>
                </GoogleButton>
              </ButtonWrapper>
            </HeroWrapper>
            <Vector src={hubspotWebflow} maxWidth="45%" paddingTop="5vh" breakpoint={theme.breakpoints.tablet} responsiveStyle="display:none;"/>
          </FlexRowWrapper>
          <FlexRowWrapper marginTop="10vh" breakpoint={theme.breakpoints.mobile} responsiveStyle="flex-direction: column-reverse; align-items: center;">
            <Vector src={sittingDoodle} maxWidth="25%"/>
            <SectionHeader
              title="There’s no easy way to use Webflow forms with Hubspot."
              maxWidth="55%"
              align="left"
              marginLeft="5%"
            >
              Weblow forms don’t have email domain verification and other powerful marketing automation features that
               Hubspot provides out of the box. Embedding a Hubspot form directly on Webflow severely limits your styling options.
              <br/><br/>
              We’ve been there. Many times. It’s plain simple annoying.
            </SectionHeader>
          </FlexRowWrapper>
        </Container>
      </Wrapper>
      <Wrapper 
        backgroundImage={`url(${solutionBlob})`}
        backgroundSize={`35% auto`}
        backgroundPosition={`top right`}
        backgroundRepeat={`no-repeat`}
        breakpoint={theme.breakpoints.tablet}
        responsiveStyle={`background-size: 50% auto;`}
        minHeight="93vh"
      >
        <Container>
          <FlexRowWrapper 
            marginTop="10vh" 
            horizontalAlign="space-between" 
            breakpoint={theme.breakpoints.mobile} 
            responsiveStyle="flex-direction: column; align-items: center;"
          >
            <SectionHeader
              paddingTop="20vh"
              title="So we created a free tool."
              maxWidth="50%"
              align="left"
            >
              <div>
                As <ExternalLink display="inline" to="https://elironadvisory.com/work" fontWeight="600">growth advisors</ExternalLink>,
                 we’ve helped several startups build websites and run digital marketing.
                 Often that meant using Webflow and Hubspot together. So we built a tool to make this easy (for ourselves)!
              </div>
               <ButtonWrapper paddingTop="20px" paddingBottom="20px">
                {
                  (user && !waiting) && 
                    <Link to="/app/websites">
                      <Button>View my websites</Button>
                    </Link>
                }
                <GoogleButton 
                  waiting={waiting}
                  setWaiting={setWaiting}
                  show={!user || waiting}
                >
                  Sign up with Google
                </GoogleButton>
              </ButtonWrapper>
            </SectionHeader>
            <Vector src={zenDoodle} maxWidth="45%"/>
          </FlexRowWrapper>

        </Container>
      </Wrapper>
      <Container>
        <FlexColumnWrapper 
          marginBottom="40px" 
          marginTop="-60px" 
          breakpoint={theme.breakpoints.small} 
          responsiveStyle="margin-top: 0px;"
        > 
          <SectionHeader
            title="How it works."
          >
            It takes 5 mins tops!
          </SectionHeader>
          <FlexRowWrapper 
            marginTop="40px" 
            marginBottom="40px" 
            horizontalAlign="center" 
            breakpoint={theme.breakpoints.mobile}  
            responsiveStyle="flex-direction: column; align-items: center; margin-top: 20px; margin-bottom: 20px;"
          >
            <FlexColumnWrapper 
              minWidth="28%" 
              textAlign="center" 
              marginLeft="2.5%" 
              marginRight="2.5%" 
              verticalAlign="center" 
              marginBottom="40px"
            >
              <Vector src={profileIcon} maxHeight="60px"/>
              <Step
                stepNumber="Step 1"
                stepTitle="Signup"
                maxWidth="200px"
              >
                Create a free Vimkit account                  
              </Step>
            </FlexColumnWrapper>
            <FlexColumnWrapper  
              minWidth="28%" 
              textAlign="center" 
              marginLeft="2.5%" 
              marginRight="2.5%" 
              verticalAlign="center" 
              marginBottom="40px"
            >
              <Vector src={addIcon} maxHeight="60px" />
              <Step
                stepNumber="Step 2"
                stepTitle="Add your website"
                maxWidth="200px"
              >
                Paste the Vimkit code snippet on your Webflow site                  
              </Step>
            </FlexColumnWrapper>
            <FlexColumnWrapper 
              minWidth="28%" 
              textAlign="center" 
              marginLeft="2.5%" 
              marginRight="2.5%" 
              verticalAlign="center" 
              marginBottom="40px"
            >
              <Vector src={connectIcon} maxHeight="60px"/>
              <Step
                stepNumber="Step 3"
                stepTitle="Connect your form"
                maxWidth="200px"
              >
                Connect your Hubspot account to your Webflow form                 
              </Step>
            </FlexColumnWrapper>
          </FlexRowWrapper>
        </FlexColumnWrapper>
      </Container>
      <Wrapper
        backgroundImage={`url(${faqWave})`}
        backgroundSize={`100% auto`}
        backgroundPosition={`center top`}
        backgroundRepeat={`no-repeat`}

      >
        <Container>
          <FlexRowWrapper 
            marginTop="10vh" 
            horizontalAlign="space-between" 
            verticalAlign="flex-start" 
            breakpoint={theme.breakpoints.mobile}  
            responsiveStyle="flex-direction: column; align-items: center;"
          >
            <FlexColumnWrapper 
              marginLeft="5%" 
              breakpoint={theme.breakpoints.mobile}  
              responsiveStyle="margin-left:auto;margin-right:auto;"
            >
              <SectionHeader
                title="FAQs"
                maxWidth="65%"
                align="left"
              />
              <AccordianPanel 
                maxWidth="700px" 
                breakpoint={theme.breakpoints.mobile}
                responsiveStyle="text-align: center; align-items: center;"
              >
                <Accordian title="Can’t I just use Webflow’s forms?" expand={true}>
                  You can but Hubspot’s forms do a lot more.
                   They check MX records to verify if an email is valid.
                   They can show you the sequence of page visits and submissions from a visitor...
                   even on the free tier!
                   Hubspot's paid tiers have other powerful capabilities like automated workflows.
                   All that helps you capture more leads.
                </Accordian>
                <AccordianDivider/>
                <Accordian title="Can’t I just embed a Hubspot form?" >
                  Yes but the styling options are super limited on Hubspot compared to what Webflow allows.
                   Why settle for less? Your website should be beautiful. 
                </Accordian>
                <AccordianDivider/>
                <Accordian title="I use Zapier to connect Webflow to Hubspot. How is this different?" >
                  Zapier connections are one-way. That means you can't do real-time validation of user input using Hubspot's advanced rules
                   (e.g. tell a visitor that the email they provided is invalid). Oh and you need to pay for Zapier too! 
                </Accordian>
                <AccordianDivider/>
                <Accordian title="Will this work with Hubspot’s free tier?">
                  Yes! You can use Vimkit with both free and paid tiers. 
                </Accordian>
                <AccordianDivider/>
                <Accordian title="Will this work on free Webflow accounts?" >
                  No. You need a paid Webflow account or a site plan to add Vimkit to your Webflow site. 
                </Accordian>
                <AccordianDivider/>
                <Accordian title="What’s the catch? How is this free?">
                  We built this tool for our own use and are happy to share it at no cost.
                   We're building other advanced tools that will be available on a paid plan. 
                </Accordian>
              </AccordianPanel>
            </FlexColumnWrapper>
            <Vector 
              src={faqDoodle} 
              maxWidth="15%" 
              paddingTop="5vh" 
              paddingBottom="5vh" 
              breakpoint={theme.breakpoints.tablet} 
              responsiveStyle="display:none;"
            />
          </FlexRowWrapper>
          
        </Container>
      </Wrapper>
      <Container>
        <FlexColumnWrapper marginBottom="5vh" marginTop="5vh"> 
          <SectionHeader
            title="Let's get started."
          >
            Free. No coding needed. The full power of Hubspot and Webflow.
          </SectionHeader>
          <ButtonWrapper>
            {
              (user && !waiting) && 
              <Link to="/app/websites">
                <Button>View my websites</Button>
              </Link>
            }
            <GoogleButton 
              waiting={waiting}
              setWaiting={setWaiting}
              show={!user || waiting}
            >
              Sign up with Google
            </GoogleButton>
          </ButtonWrapper>
        </FlexColumnWrapper>
      </Container>
    </SiteLayout>    
  )
}

export default HubspotWebflowFormTool
