import React from "react"
import styled from "styled-components"

const StepNumber = styled.div`
    padding-top: 10px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.extraSubtleText};
`

const StepTitle = styled.h4`
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 16px;
`

const StepContent = styled.div`
`

const StepWrapper = styled.div`
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
`

export default ({stepTitle, stepNumber, children, ...rest}) => (
    <StepWrapper {...rest}> 
        <StepNumber>{stepNumber}</StepNumber>
        <StepTitle>{stepTitle}</StepTitle>
        <StepContent>{children}</StepContent>
    </StepWrapper>    
)